import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Co-Everything: Incentivizing Talent In The EntrepreWork Era",
  date: "2017-07-06",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170706-0.jpg",
  path: "2017-07-06-Co-Everything",
  teaserText: "The creative ideas economy needs people that are inspired and can work in groups to make bigger things, unencumbered."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Co-Everything: Incentivizing Talent In The EntrepreWork Era" description="The creative ideas economy needs people that are inspired and can work in groups to make bigger things, unencumbered." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`Companies like Facebook, Google and Warby Parker have revolutionized how we work. The first thing they did was to get rid of the inflexibilities of the traditional corporation and put the human experience at the forefront. They built business models fueled by employee freedom and their ability to trigger innovation and creativity. And while these newer organizational models struggle with certain aspects of their work culture (see Uber), their freedom-granting approach will grow in importance as we continue at a full-speed-ahead shift towards creativity and innovation.`}</MDXTag>
      <ImageLandscape imageSrc="20170706-1.jpg" caption="Google Office" />
      <MDXTag name="p" components={components}>{`It is this innovation-centric open model that over the past twenty years has fueled the vast waves of digital innovations and capitalized on a shift towards software and services. This shift has fueled a demand for a new breed of talent that can both design a product and creatively get it to market. This new tech/touch employee, increasingly coming from younger work generations, is turned off by clunky red tape and thus thinks creatively and entrepreneurially at all times.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Their love for the freedom of entrepreneurship is fueled by the meteoric rise of new titans of industry such as Reed Hastings and Mark Zuckerberg as well as by their skepticism towards corporate America. They’ve seen the challenges their parents faced during the recession and grew not to trust big. They also shun a lot of corporate excesses and instead want to focus on bridging the gap between their work and their values. They are willing to have their work and personal lives blend more, and that is a highly entrepreneurial trait. Organizations must open up and adapt to engage this new employee/consumer.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This resolution around work and entrepreneurship calls for a different philosophy and approach towards recruitment, mentorship and partnering with next gen employees. The guiding framework is around a job market where the good employees command the conversation, just like they do in many other industries and roles (think consumers in the on-demand economy.)`}</MDXTag>
      <MDXTag name="p" components={components}>{`This new employee-centric dynamic demands that recruiting companies be more attuned with employees’ changing needs, more flexible in the types of environments they provide and the offers they make, and overall, be much more involved in reassessing and perfecting what constitutes an incentive system.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The following are some ways to better incentivize a new generation of employees and brand advocates:`}</MDXTag>
      <ImageLandscape imageSrc="20170706-2.jpg" caption="Netflix Culture" />
      <MDXTag name="p" components={components}>{`Talent/HR Compact. The first step is the creation of a talent-based plan crafted between your HR/talent departments and your actual staff. Work with your employees to identify the issues that matter to them and the most powerful ways of addressing them. Come up with fun ways of getting feedback and ideas: think brainstorm sessions, online anonymous suggestions boxes, as well as more formal elements such as benchmarking of best practices from other companies and industries. Netflix’s HR game-changing plan and vision around “Freedom & Responsibility” speaks to this way of building a partnership around talent that nurtures thriving and loyal employees.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The Art of The Search, and Match.  Traditional, self-selection-influenced models will not work going forward. Explore new ways of recruiting (video interviews, recommendations, creative exercises, earlier pipeline development) and make it fun. Think of different profiles than before, i.e. recruit at different schools, explore more creative majors in school, identify dropouts that are doing extraordinary things as well as other unexpected profiles, etc. Open up the process to be more egalitarian and preferential to the employee: have them set the schedules, identify who they want to interview at the company, etc. In essence, set the tone from the onset that you are a company that values them and their input.`}</MDXTag>
      <ImageLandscape imageSrc="20170706-3.jpg" caption="Sheryl Sandberg" />
      <MDXTag name="p" components={components}>{`Show Me What You Stand For: Transparency. The incoming consumer and worker wants transparency on all fronts. They’re willing to give up their time and effort but want to make sure it’s rewarding and compatible with how they see the world. They want to understand corporate and brand purpose and their role in advancing them. Allow those most passionate to help you ensure sustainability is present in all aspects of your business. Identify the issues you will invest in, come up with ways to empower and encourage volunteering or other forms of involvement.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Flexibility. This is one of the requisite traits to thrive in a new economy and it powerfully applies to engaging employees. They want flexibility in terms of professional exposure and training, schedule, telecommuting possibilities, paid sabbaticals, prosocial assignments. Explore the opportunities in letting them venture into entrepreneurial initiatives that can add value to your strategic plans. Look for new models that can allow them to stay connected: i.e. as more vested freelancers, working part time with non-competitive companies, independent sales agents or referral providers, etc. No one better knows your business as well as ways to improve it and disrupt it than your employee. Don’t let all these insights go to a competitor.`}</MDXTag>
      <ImageLandscape imageSrc="20170706-4.jpg" caption="Starbucks" />
      <MDXTag name="p" components={components}>{`Count What Matters: New Success Metrics. Metrics of old should not apply to new more innovative settings. Think of ways of measuring new forms of skills. For instance, think of ways to encourage and track how your sales teams connect with key prospects. Count things beyond just sales numbers, explore ways to measure the ways in which they’re gathering insights, length and substantive level of conversations, lifetime value vs. short term sales tracking, etc. Think of new metrics to apply to your company as well. For instance, is there a happiness index you can add to your performance dashboard? Reward supervisors for the success of their reports, encourage mentorship at all levels.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fun and Play. This employee is experience-driven. In it they find their peace, groove and inspiration. If you’re in the business of ideas, you should explore to the fullest. Think of how you can introduce fun and play into your entire employee journey. From more human-centric, real and funny ways of sharing your corporate story and that of your employees, to ways in which gamification can make training more engaging, to deploying communication programs like Slack that make chatting and working seamlessly fun.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Amplify Their Upside. Again, this generation has an entrepreneurial hunger to make things happen and to make statements while realizing financial gains. Find ways to advance traditional financial incentives, giving them more skin in the game. Reward strategic and creative behavior. Also find non-financial ways of rewarding them. Invest in their education. Grant them access to your top leadership for unique projects. Simply think of all the ways they can feel like they’re part of the A-team magic.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Wellness As Strategic Imperative. A healthy body and mind go hand in hand. Play up to your mutual need for healthy, happy employees by bringing to life a wellness initiative. This begins with fostering better work/life balances to the introduction of wellness practices such as gym membership allowances, creation of calm-meditative spaces, and facilitating outside workshops on wellbeing. But above everything else, the best way of ensuring wellness is by making sure your culture is toxic-free. Show your employees that there is not tolerance for negative attitudes and demonstrate the opposite—that a can- do innovative culture thrives on respect for the other.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The creative ideas economy needs people that are inspired and can work in groups to make bigger things, unencumbered. Your job should be to bring talent that thrives in this environment and to nurture and protect that sense of space where everybody brings their best self to work, and your company and world benefit. Here’s to amplifying the human potential.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    